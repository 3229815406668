import PeliasRequest from './pelias/request';

const Pelias = function (locationType) {
  this.geocode = function (term, callback) {
    const request = new PeliasRequest({
      locationType: locationType,
    });
    request.autoComplete(term, callback);
  };

  this.reverseGeocode = function (latitude, longitude, callback) {
    const request = new PeliasRequest({
      locationType: 'locality,localadmin,borough,macrohood,postalcode,county',
    });
    request.reverseGeocode(latitude, longitude, callback);
  };

  this.resultSelected = function (autoCompletedResult, callback) {
    callback(autoCompletedResult);
  };
};

export default Pelias;
