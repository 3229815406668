import * as React from 'react';
import $ from './jquery';
// @ts-expect-error - TS7016 - Could not find a declaration file for module 'shared/DEPRECATED/components/Input'. 'app/webpack/javascripts/shared/DEPRECATED/components/Input.js' implicitly has an 'any' type.
import Input from 'shared/DEPRECATED/components/Input';

type LocationControlType = {
  Providers: {
    [key: string]: {
      apiKey: string;
    };
  };
};

// @ts-expect-error - TS2339 - Property 'LocationControl' does not exist on type 'Window & typeof globalThis'.
const _LocationControl: LocationControlType = window.LocationControl;

type Props = {
  locateMe: boolean;
  onLocationSuggestionSelected: (arg1?: any) => void;
  provider: 'Mapbox';
  locationType: string;
  disabled: boolean;
  apiKey?: string;
  language?: string;
  country?: string | Array<string> | null;
  initialValue: string | null | undefined;
};

type State = {
  text: string;
};

let id = 1;

/**
 * Just a wrapper component around `location_control` library.
 * This still requires that the following be explicitly added:
 * - location_control
 * - jQuery (internally used by location_control)
 * - the API key via `js_metadata_container_tag` in the view so that it can be set here
 * */
export default class LocationControl extends React.Component<Props, State> {
  static defaultProps = {
    locateMe: false,
    onLocationSuggestionSelected: () => {},
    provider: 'Mapbox',
    locationType: 'city',
    disabled: false,
  };

  // @ts-expect-error - TS2564 - Property 'apiKey' has no initializer and is not definitely assigned in the constructor.
  apiKey: string;
  component: React.ReactNode;

  constructor(props: Props) {
    super(props);

    if (typeof $ === 'undefined' || typeof _LocationControl === 'undefined') {
      throw new Error(
        'jQuery and/or LocationControl legacy dependencies are not available!'
      );
    }

    this.ensureApiKey();

    this.state = {
      text: props.initialValue || '',
    };
  }

  ensureApiKey() {
    // the api key must be injected for this to work properly so throw an error if it can't
    // detect it
    this.apiKey =
      $(
        `[data-key="LocationControl.Providers.${this.props.provider}.apiKey"]`
      ).data('value') || this.props.apiKey;

    if (typeof this.apiKey === 'undefined') {
      throw new Error(
        `the API key is not defined for the provider: ${this.props.provider}`
      );
    }
  }

  componentDidMount() {
    const $elem = $(this.component);
    const { locateMe, provider, locationType, language, country } = this.props;

    // setup the attribute 'name' used internally by location control to setup the hidden fields
    // it needs to maintain state
    $elem.attr('name', `location_control_${id++}`);

    // set the API key that should have been injected on the page
    _LocationControl.Providers[this.props.provider].apiKey = this.apiKey;

    $elem.locationControl({
      locateMe: locateMe,
      // @ts-expect-error - TS7006 - Parameter 'e' implicitly has an 'any' type.
      onLocationSelect: (e) => {
        this.setState({ text: e.displayName }, () => {
          this.props.onLocationSuggestionSelected(e);
        });
      },
      provider: provider,
      type: locationType,
      country: country,
      language: language,
    });
  }

  render() {
    return (
      <Input
        type="text"
        // @ts-expect-error - TS7006 - Parameter 'input' implicitly has an 'any' type.
        inputRef={(input) => {
          this.component = input;
        }}
        // @ts-expect-error - TS2783 - 'disabled' is specified more than once, so this usage will be overwritten.
        disabled={this.props.disabled}
        {...this.props}
        value={this.state.text || ''}
        // @ts-expect-error - TS7006 - Parameter 'e' implicitly has an 'any' type.
        onChange={(e) => {
          this.setState({ text: e.target.value });
          this.props.onLocationSuggestionSelected(null);
        }}
      />
    );
  }
}
