import $ from 'jquery';
import AutoComplete from './auto_complete';
import Control from './control';
import LocateMe from './locate_me';
import Providers from './providers';

$.widget('gh.locationControl', {
  options: {
    locateMe: true,
    language: null,
    onLocationSelect: function () {},
    provider: 'Mapbox',
    type: 'city',
    locateMeText: 'Locate me',
    country: null, // All countries by default
  },

  _create: function () {
    let locateMe = this.element.data('locate-me');
    const onLocationSelect = this.options.onLocationSelect;
    const providerName = this.element.data('provider') || this.options.provider;
    const type = this.element.data('type') || this.options.type;
    const locateMeText = this.options.locateMeText;
    const language = this.element.data('language') || this.options.language;
    const country = this.element.data('country') || this.options.country;
    const provider = new Providers[providerName](type, language, country);
    const control = new Control(provider, this.element, onLocationSelect);

    if (locateMe === undefined) {
      locateMe = this.options.locateMe;
    }

    if (locateMe) {
      new LocateMe(control, locateMeText);
    }

    new AutoComplete(control);
  },

  clear: function () {
    this.element.val('').blur(); // blur triggers
  },

  disable: function () {
    this.element.attr('disabled', true).addClass('disabled');
  },

  enable: function () {
    this.element.attr('disabled', false).removeClass('disabled');
  },
});
